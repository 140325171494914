exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-lumbago-index-jsx": () => import("./../../../src/pages/lumbago/index.jsx" /* webpackChunkName: "component---src-pages-lumbago-index-jsx" */),
  "component---src-pages-pain-index-jsx": () => import("./../../../src/pages/pain/index.jsx" /* webpackChunkName: "component---src-pages-pain-index-jsx" */),
  "component---src-pages-scratch-bruise-index-jsx": () => import("./../../../src/pages/scratch/bruise/index.jsx" /* webpackChunkName: "component---src-pages-scratch-bruise-index-jsx" */),
  "component---src-pages-scratch-dislocation-index-jsx": () => import("./../../../src/pages/scratch/dislocation/index.jsx" /* webpackChunkName: "component---src-pages-scratch-dislocation-index-jsx" */),
  "component---src-pages-scratch-fracture-index-jsx": () => import("./../../../src/pages/scratch/fracture/index.jsx" /* webpackChunkName: "component---src-pages-scratch-fracture-index-jsx" */),
  "component---src-pages-scratch-index-jsx": () => import("./../../../src/pages/scratch/index.jsx" /* webpackChunkName: "component---src-pages-scratch-index-jsx" */),
  "component---src-pages-scratch-muscle-index-jsx": () => import("./../../../src/pages/scratch/muscle/index.jsx" /* webpackChunkName: "component---src-pages-scratch-muscle-index-jsx" */),
  "component---src-pages-scratch-sprain-index-jsx": () => import("./../../../src/pages/scratch/sprain/index.jsx" /* webpackChunkName: "component---src-pages-scratch-sprain-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */)
}

